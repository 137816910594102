/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:root{
    --colorFondoPrincipal: rgb(70, 131, 180);
    --colorFondoObscuro: rgb(43, 83, 116);
    --colorFondoClaro: rgb(248, 248, 248);
    --colorBordesPrincipal: lightgray;
    --colorTextoClaro: #fdfdfd;
    --colorTextoObscuro: black;
  }

  .titulo_componente{
    color: var(--colorFondoObscuro);
    font-family: 'Raleway', sans-serif;
    font-size: calc(1.5em + 1vw);
    font-weight: bold;
}